export const deepEqual = (a: any, b: any): boolean => {
    if (a === b) return true;

    if (typeof a === "function" && typeof b === "function") {
        return a.toString() === b.toString();
    }

    if (
        typeof a !== "object" ||
        a === null ||
        typeof b !== "object" ||
        b === null
    )
        return false;

    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    if (keysA.length !== keysB.length) return false;

    for (const key of keysA) {
        if (!keysB.includes(key) || !deepEqual(a[key], b[key])) return false;
    }

    return true;
};

export const getExcludedKeys = <T extends Record<string, string | number>>(
    enumObject: T,
    excludeKeys: string[]
): string[] => {
    return Object.keys(enumObject).filter(
        (key) => isNaN(Number(key)) && !excludeKeys.includes(key)
    );
};

export const getValuesFromKeys = <T extends Record<string, string | number>>(
    enumObject: T,
    keys: string[]
): T[keyof T][] => {
    return keys
        .map((key) => enumObject[key as keyof T])
        .filter((value): value is T[keyof T] => value !== undefined);
};

export const getNestedValue = (obj: any, path: string): any => {
    if (!path) return obj; // Return the root object if the path is empty
    return path
        .split(".")
        .reduce(
            (acc, key) =>
                acc && acc[key] !== undefined ? acc[key] : undefined,
            obj
        );
};
