import styled from "styled-components";
import { colorsDeprecated as c, properties as p } from "global-style";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import colors from "theme/colors";

export const OpaqueTooltip = styled(({ className, ...other }) => (
    <Tooltip classes={{ popper: className }} {...other} />
))`
    & .${tooltipClasses.tooltip} {
        box-shadow: ${p.shadowMd};
        background-color: ${colors.text};
        color: ${colors.white};
        font-size: 0.875rem;
        padding: ${({ $slim }) => ($slim ? `0.25rem 0.5rem` : `0.75rem 1rem`)};
        ${({ $noWrap }) => ($noWrap ? `max-width: none` : ``)};
        a {
            color: ${c.loopColA};
        }
    }
    & .${tooltipClasses.arrow} {
        color: ${colors.text};
        &:before {
            box-shadow: ${p.shadowMd};
            background-color: ${colors.text};
        }
    }
`;

export const Prompt = styled.span<{ $abbr: boolean }>`
    display: inline-flex;
    ${({ $abbr }) => ($abbr ? `text-decoration: underline dotted` : ``)};
`;
