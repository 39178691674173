import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { AvailableNetwork } from "default-variables";
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum";
import {
    DynamicContextProps,
    DynamicUserProfile,
    useDynamicContext,
} from "@dynamic-labs/sdk-react-core";
import { SolanaWalletConnectors } from "@dynamic-labs/solana";
import WalletModal from "components/WalletModal";
import withDynamicContext from "context/Wallet/hoc/withDynamicContext";
import useWalletBalance, {
    GetUpdatedTokenBalanceProps,
    TokenBalanceProps,
} from "context/Wallet/hooks/useWalletBalance";
import useWalletConnected, {
    ConnectedWallet,
    PrimaryWalletAccount,
} from "context/Wallet/hooks/useWalletConnected";
import useWalletNetwork, {
    SetConnectedNetworkProps,
} from "context/Wallet/hooks/useWalletNetwork";
import useWalletSignature, {
    WalletSignerToken,
} from "context/Wallet/hooks/useWalletSignature";
import useWalletEmail from "context/Wallet/hooks/useWalletEmail";
import useWalletAllowance, {
    GetTokenAllowanceProps,
    SetTokenAllowanceProps,
    TokenAllowanceProps,
} from "context/Wallet/hooks/useWalletAllowance";
import useWalletSafes, {
    SafeWallet,
} from "context/Wallet/hooks/useWalletSafes";
import useWalletLogin from "context/Wallet/hooks/useWalletLogin";
import useWalletTransaction, {
    SendTokenPaymentProps,
    WalletTransactionData,
} from "context/Wallet/hooks/useWalletTransaction";

export interface LocalhostWalletEmail {
    wallet: string;
    email: string;
}

export interface WalletProviderValue {
    walletConnected: PrimaryWalletAccount | null;
    networkConnected: AvailableNetwork | null;
    walletsAvailable: ConnectedWallet[];
    safeWallet: Promise<SafeWallet | null>;
    isWalletModalOpen: boolean;
    isWalletConnecting: boolean;
    requiresDynamicLogin: boolean;
    isDynamicLoggedIn: boolean;
    walletSessionToken: string | null;
    isWalletConnected: boolean;
    isNetworkSetting: boolean;
    handleConnectWallet: () => void;
    handleWalletLogout: () => Promise<void>;
    setPrimaryWallet: (wallet: string) => void;
    setProxyWallet: (proxyAddr: string | null) => void;
    setConnectedNetwork: (
        options: SetConnectedNetworkProps
    ) => Promise<boolean>;
    hasTokenBalanceStored: (options: TokenBalanceProps) => boolean;
    getTokenBalance: (options?: GetUpdatedTokenBalanceProps) => Promise<string>;
    hasTokenAllowanceStored: (options: TokenAllowanceProps) => boolean;
    getTokenAllowance: (options: GetTokenAllowanceProps) => Promise<bigint>;
    setTokenAllowance: (
        options: SetTokenAllowanceProps
    ) => Promise<bigint | null>;
    setTokenAllowanceIfInsufficient: (
        options: SetTokenAllowanceProps
    ) => Promise<bigint | null | false>;
    addToTokenAllowance: (
        options: SetTokenAllowanceProps
    ) => Promise<bigint | null>;
    setWalletEmail: (email: string) => void;
    getWalletEmail: () => string | null;
    getSignedMessage: () => Promise<WalletSignerToken | false>;
    generateTransferSignatureToken: (props: any) => Promise<any>;
    verifySignedMessage: (signature: string) => boolean | undefined;
    sendTokenPayment: (
        params: SendTokenPaymentProps
    ) => Promise<WalletTransactionData>;
    confirmTransaction: (params: WalletTransactionData) => Promise<boolean>;
}

const WalletContext = createContext<WalletProviderValue | undefined>(undefined);

interface WalletProviderProps {
    children: ReactNode;
    requiresDynamicLogin?: boolean;
}

const WalletProvider = ({
    children,
    requiresDynamicLogin = false,
}: WalletProviderProps) => {
    const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);

    const {
        handleLogOut: handleWalletLogout,
        setShowDynamicUserProfile,
        showDynamicUserProfile,
        setShowAuthFlow,
        showAuthFlow,
        // [ ] Separate handleLogOut() and hanelDisconnectWallet() functionality
        /* handleDisconnectWallet: (walletId: string) => void  */
    } = useDynamicContext();

    const { isDynamicLoggedIn, walletSessionToken } =
        useWalletLogin(requiresDynamicLogin);

    const willUseDynamicUserProfilePopup =
        !requiresDynamicLogin || isDynamicLoggedIn;

    const { network, isNetworkSetting, setConnectedNetwork } =
        useWalletNetwork();

    // [ ] Provider should be moved to the network object!!!
    const {
        isWalletConnecting,
        wallet,
        walletsAvailable,
        setPrimaryWallet,
        setProxyWallet,
    } = useWalletConnected(network?.id);

    const { getTokenBalance, hasTokenBalanceStored } = useWalletBalance(
        wallet,
        network
    );

    const { setWalletEmail, getWalletEmail } = useWalletEmail(wallet);

    const {
        generateTransferSignatureToken, // [ ] No longer supported, consider removal
        getSignedMessage,
        verifySignedMessage,
    } = useWalletSignature(wallet, network);

    const {
        hasTokenAllowanceStored,
        getTokenAllowance,
        setTokenAllowance,
        addToTokenAllowance,
        setTokenAllowanceIfInsufficient,
    } = useWalletAllowance(wallet, network);

    const { safe } = useWalletSafes(wallet, network);

    const { sendTokenPayment, confirmTransaction } = useWalletTransaction(
        wallet,
        safe
    );

    const isWalletConnected = !!wallet?.address;

    const connectWallet = useCallback(() => {
        return isWalletConnected
            ? () => setShowDynamicUserProfile(true)
            : () => setShowAuthFlow(true);
    }, [setShowDynamicUserProfile, setShowAuthFlow, isWalletConnected]);

    useEffect(() => {
        if (!willUseDynamicUserProfilePopup) return;
        setIsWalletModalOpen(showDynamicUserProfile || showAuthFlow);
    }, [willUseDynamicUserProfilePopup, showDynamicUserProfile, showAuthFlow]);

    const value = useMemo<WalletProviderValue>(
        () => ({
            walletConnected: wallet,
            networkConnected: network,
            walletsAvailable,
            safeWallet: safe,
            isWalletModalOpen,
            isWalletConnecting,
            isWalletConnected,
            requiresDynamicLogin,
            isDynamicLoggedIn,
            walletSessionToken,
            isNetworkSetting,
            handleConnectWallet: connectWallet(),
            handleWalletLogout,
            setPrimaryWallet,
            setProxyWallet,
            setConnectedNetwork,
            hasTokenBalanceStored,
            getTokenBalance,
            hasTokenAllowanceStored,
            getTokenAllowance,
            setTokenAllowance,
            setTokenAllowanceIfInsufficient,
            addToTokenAllowance,
            setWalletEmail,
            getWalletEmail,
            getSignedMessage,
            generateTransferSignatureToken,
            verifySignedMessage,
            sendTokenPayment,
            confirmTransaction,
        }),
        [
            wallet,
            network,
            safe,
            walletsAvailable,
            isWalletModalOpen,
            isWalletConnecting,
            isWalletConnected,
            requiresDynamicLogin,
            isDynamicLoggedIn,
            walletSessionToken,
            isNetworkSetting,
            connectWallet,
            handleWalletLogout,
            setPrimaryWallet,
            setProxyWallet,
            setConnectedNetwork,
            hasTokenBalanceStored,
            getTokenBalance,
            hasTokenAllowanceStored,
            getTokenAllowance,
            setTokenAllowance,
            setTokenAllowanceIfInsufficient,
            addToTokenAllowance,
            setWalletEmail,
            getWalletEmail,
            getSignedMessage,
            generateTransferSignatureToken,
            verifySignedMessage,
            sendTokenPayment,
            confirmTransaction,
        ]
    );

    return (
        <WalletContext.Provider value={value}>
            {children}
            {!willUseDynamicUserProfilePopup ? (
                <WalletModal
                    onStateChange={setIsWalletModalOpen}
                    isWalletConnecting={isWalletConnecting}
                    isWalletConnected={isWalletConnected}
                />
            ) : (
                <DynamicUserProfile variant="modal" />
            )}
        </WalletContext.Provider>
    );
};

const settings: DynamicContextProps["settings"] = {
    environmentId: import.meta.env.VITE_DYNAMIC_ENVIRONMENT_ID,
    walletConnectors: [EthereumWalletConnectors, SolanaWalletConnectors],
    enableVisitTrackingOnConnectOnly: true,
    privacyPolicyUrl: "https://loopcrypto.xyz/privacy-policy",
    termsOfServiceUrl: "https://loopcrypto.xyz/terms-of-service",
    onboardingImageUrl:
        "https://loop-entity-logos.s3.us-east-2.amazonaws.com/loop-crypto-long.svg",
    cssOverrides: `
        .collect-user-data__main-img {
            width: 80%;
            margin: 1rem auto;
        }
    `,
    walletsFilter: (wallets) =>
        wallets.filter(
            (wallet) =>
                ![`safe`, `walletconnect`, `ledger`, `phantomledger`].includes(
                    wallet.key
                )
        ),
    // [ ] May need to set z-index to walletConnect (401)
};

export const WalletContextProvider = withDynamicContext<WalletProviderProps>(
    WalletProvider,
    settings
);

export const useWallet = (): WalletProviderValue => {
    const context = useContext(WalletContext);
    if (context === undefined) {
        throw new Error(`useWallet() must be used within a WalletProvider`);
    }
    return context;
};

export default WalletContextProvider;
