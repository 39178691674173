import * as S from "./style";
import {
    createRef,
    RefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import { parseUnits, formatUnits } from "ethers";
import {
    apiServerUrl,
    DOLLAR_SCALE_FACTOR,
    PERCENTAGE_ORDER_OF_MAGNITUDE,
} from "default-variables";
import { SmartContractType } from "company/routes/Developer";
import { Company, AmountType, FeeConfigurationType } from "company/types";
import { CompanyAgreementResponse } from "api/types/company";
import { formatDateTimeFromSeconds } from "utils/datetime";
import {
    formatUnitsWithPrecision,
    toDollar,
    toFixed,
    undecimalizeNumber,
} from "utils/financial";
import { firstToUpper } from "utils/strings";
import { shortenAddress } from "utils/addresses";
import { useNotificationQueue } from "context/NotificationQueue";
import { useAvailableNetworks } from "hooks/useAvailableNetworks";
import { useModal } from "context/ModalProvider";
import AuthorizationInformation, {
    Authorization,
} from "company/components/BatchPayForm/AuthorizationInformation";
import PaymentReceiverFieldset, {
    PaymentReceiver,
    PaymentReceiverFieldsetRef,
} from "company/components/BatchPayForm/PaymentReceiverFieldset";
import SubmitSteps from "company/components/BatchPayForm/SubmitSteps";
import TransferBillDateMultipleField, {
    TransferBillDateMultipleFieldRef,
} from "company/components/transfers/TransferBillDateMultipleField";
import AllowanceNewWallet from "company/components/AllowanceNewWallet";
import Anchor from "components/Anchor";
import Button, { ButtonSizes, ButtonVariants } from "components/Button";
import Checkbox from "components/Checkbox";
import Field from "components/Field";
import Hint from "components/Hint";
import Label from "components/Label";
import Select, { SelectOption } from "components/Select";
import SelectWithIcon from "components/SelectWithIcon";
import TagsInput, { TagsInputRef } from "company/components/TagsInput";
import ErrorMessage from "components/ErrorMessage";
import { NotificationType } from "components/Notification";
import {
    CommonBlockchainNetworkResponse,
    GeneralTokenDetailsResponse,
} from "api";
import { useEns } from "context/EnsProvider";
import { useUser } from "context/User";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";
import { DescriptionListItem } from "components/DescriptionList";
import { convertCentsToToken } from "utils/exchangeRates";
import { toNumber } from "utils/numbers";
import SubSection from "components/SubSection";
import { Spacing } from "theme/spacing";
import { useWallet } from "context/Wallet";
import { useGetCompanyAgreements } from "company/hooks/useGetCompanyAgreements";
import { nonCanceledAgreementStatusNumbers } from "types/common-enums";

interface BatchPayFormProps {
    onFormSuccess: () => void;
    selectedItemId?: string;
    setSelectedItemId: (itemId: string | undefined) => void;
    selectedFromAddress?: string;
    selectedTokenAddress?: string;
    tokens: GeneralTokenDetailsResponse[];
    contracts: Company.Contract[];
    networks: CommonBlockchainNetworkResponse[];
    paymentItems: Company.Item[];
    agreements: CompanyAgreementResponse[];
    entities: Company.Entity[];
}

const BatchPayForm: React.FunctionComponent<BatchPayFormProps> = ({
    onFormSuccess,
    selectedItemId,
    setSelectedItemId,
    selectedFromAddress,
    selectedTokenAddress,
    tokens,
    contracts,
    networks,
    paymentItems,
    // agreements,
    entities,
}) => {
    // Hooks
    const { getEnsRecord } = useEns();
    const { getTokenRate } = useGetTokensMetadata();
    const { getEntityId, getSessionToken } = useUser();
    const { openModal, setCanCloseModal } = useModal();
    const { addNotification, removeNotification } = useNotificationQueue();
    const {
        generateTransferSignatureToken,
        walletConnected,
        networkConnected,
        getTokenAllowance,
        getTokenBalance,
    } = useWallet();
    const { getNetworkById } = useAvailableNetworks();

    const { agreements, getCompanyAgreementsRefetch } = useGetCompanyAgreements(
        {
            statuses: nonCanceledAgreementStatusNumbers.join(`,`),
            itemIds: selectedItemId ?? `hack-to-prevent-too-many-agreements`,
        }
    );

    useEffect(() => {
        getCompanyAgreementsRefetch();
    }, [getCompanyAgreementsRefetch]);

    // Get all variable rate contracts
    const variableRateContracts = contracts.filter(
        (c: Company.Contract) =>
            c.contractType === SmartContractType.VariableRate
    );

    // Map their networkId
    const variableRateContractsNetworkIds =
        variableRateContracts &&
        variableRateContracts.map(({ networkId }) => networkId);

    // Array of supported Networks
    const supportedNetworks = networks.filter(({ id }) =>
        variableRateContractsNetworkIds.includes(id)
    );

    // If a wallet is connected, get the related network
    const networkConnectedNetwork =
        networkConnected &&
        networks.find(
            (network) => network.hexId === networkConnected.networkId
        );

    const [networkId, setNetworkId] = useState<number>(
        variableRateContractsNetworkIds[0] || networkConnectedNetwork?.id || 0 // shrug
    );

    const { selectedNetwork, selectedNetworkData } = useMemo(() => {
        const selectedNetwork = networks?.find(({ id }) => networkId === id);
        const selectedNetworkData =
            selectedNetwork && getNetworkById(selectedNetwork.hexId);

        return { selectedNetwork, selectedNetworkData };
    }, [networkId, networks, getNetworkById]);

    const selectedContract = useMemo(() => {
        return contracts.find((contract) => networkId === contract.networkId);
    }, [contracts, networkId]);

    // Allowance widget control through search params
    const [searchParams] = useSearchParams();

    const { selectedItem, selectedItemTokens } = useMemo(() => {
        const selectedItem = paymentItems.find((i) => i.id === selectedItemId);
        const selectedItemTokens = tokens.filter((token) => {
            return (
                selectedContract?.networkId === token.networkId &&
                selectedItem?.acceptedTokens.includes(token.address)
            );
        });

        return { selectedItem, selectedItemTokens };
    }, [selectedItemId, tokens, paymentItems, selectedContract?.networkId]);

    // Entity
    const selectedEntity = entities.find(
        (entity) => entity.entityId === selectedItem?.entityId
    );

    // Transaction signing method: via Loop
    const delegatedSigning = !!selectedEntity?.delegatedSigning;

    // State - fromAddress
    const { selectedItemAgreementsOnNetwork, defaultFromAddress } =
        useMemo(() => {
            const selectedItemAgreementsOnNetwork =
                selectedItem &&
                selectedNetwork &&
                agreements.filter((a) => a.networkId === networkId);

            // [ ] THIS ADDRESS MUST ONLY BE SET IF IT'S IN THE LIST OF OPTIONS!!!

            // const isInTheListOfOptions = selectedItemAgreementsOnNetwork?.some();

            const defaultFromAddress = selectedFromAddress
                ? selectedFromAddress
                : selectedItemAgreementsOnNetwork &&
                    selectedItemAgreementsOnNetwork?.length >
                        0 /*  && selectedItemAgreementsOnNetwork[0].sender.wallet */
                  ? selectedItemAgreementsOnNetwork[0].sender.wallet
                  : "";

            return { selectedItemAgreementsOnNetwork, defaultFromAddress };
        }, [
            networkId,
            agreements,
            selectedFromAddress,
            selectedNetwork,
            selectedItem,
        ]);

    const [fromAddress, setFromAddress] = useState<string>(defaultFromAddress);

    // State - tokenAddress
    const defaultTokenAddress = selectedTokenAddress
        ? selectedTokenAddress
        : selectedItemTokens.length > 0
          ? selectedItemTokens[0].address
          : "";

    const [tokenAddress, setTokenAddress] =
        useState<string>(defaultTokenAddress);

    const selectedToken = useMemo(() => {
        if (tokenAddress) {
            return tokens.find(
                (t) =>
                    t.address.toLowerCase() === tokenAddress.toLowerCase() &&
                    t.networkId === networkId
            );
        }
    }, [tokens, tokenAddress, networkId]);

    // Reset to defaultTokenAddress when selectedItem change and the tokenAddress isn't supported
    useEffect(() => {
        const tokenFromSelectedAddress = selectedItemTokens.find(
            (t) => t.address === tokenAddress && t.networkId === networkId
        );

        if (!tokenFromSelectedAddress) {
            setTokenAddress(defaultTokenAddress);
        }
    }, [selectedItemTokens, tokenAddress, defaultTokenAddress, networkId]);

    // State - USD (true/false)
    const defaultUsd = true;
    const [usd, setUsd] = useState<boolean>(defaultUsd);

    // Convert to cents if USD, otherwise convert with token decimals
    let decimals = 2;
    if (!usd && selectedToken) {
        decimals = selectedToken.decimals;
    }

    // Reset amount input when the user change usd or selectedToken
    useEffect(() => {
        if (!usd) {
            setPaymentReceivers((paymentReceivers) =>
                paymentReceivers.map((paymentReceiver, i) => {
                    const updatedPaymentReceiver = {
                        ...paymentReceiver,
                        ...{
                            amount: "",
                        },
                    };

                    return updatedPaymentReceiver;
                })
            );
        }
    }, [selectedToken, usd]);

    // State - billDates
    // Note: Will still need to have a state here for display purposes
    const [billDatesCount, setBillDatesCount] = useState<number>(1);
    const transferBillDateMultipleFieldRef =
        useRef<TransferBillDateMultipleFieldRef>(null);

    const tagsInputRef = useRef<TagsInputRef>(null);

    // State - PaymentReceivers
    const defaultPaymentReceiver: PaymentReceiver = {
        toAddressOrEnsDomain: "",
        amount: "",
    };
    const [paymentReceivers, setPaymentReceivers] = useState<PaymentReceiver[]>(
        [defaultPaymentReceiver]
    );

    const paymentReceiversFieldsetRefs = useRef<
        RefObject<PaymentReceiverFieldsetRef>[]
    >([createRef<PaymentReceiverFieldsetRef>()]);

    // State - Button & Forms tate
    const [formEnabled, setFormEnabled] = useState<boolean>(true);
    const [buttonEnabled, setButtonEnabled] =
        useState<boolean>(delegatedSigning);
    const workingNotice = useRef<string>(``);

    const addPaymentReceiver = () => {
        const ref = createRef<PaymentReceiverFieldsetRef>();
        paymentReceiversFieldsetRefs.current.push(ref);
        setPaymentReceivers((paymentReceiver) => [
            ...paymentReceiver,
            defaultPaymentReceiver,
        ]);
    };

    const updatePaymentReceivers = (
        paymentReceiverUpdates: PaymentReceiver,
        index: number
    ) => {
        setPaymentReceivers((paymentReceivers) =>
            paymentReceivers.map((paymentReceiver, i) => {
                const updatedPaymentReceiver = {
                    ...paymentReceiver,
                    ...paymentReceiverUpdates,
                };

                return i !== index ? paymentReceiver : updatedPaymentReceiver;
            })
        );
    };

    const removePaymentReceiver = (index: number) => {
        paymentReceiversFieldsetRefs.current.splice(index, 1);
        setPaymentReceivers((paymentReceivers) =>
            paymentReceivers.filter((paymentReceiver, i) => i !== index)
        );
    };

    const removeWorkingNotice = () => {
        if (!workingNotice.current) return;
        removeNotification(workingNotice.current);
        workingNotice.current = ``;
    };

    const enableForm = () => {
        setFormEnabled(true);
        removeWorkingNotice();

        const validPaymentDetails =
            fromAddress.length > 0 && tokenAddress.length > 0;
        const hasSigner = delegatedSigning;

        setButtonEnabled(validPaymentDetails && hasSigner);
    };

    const handleCreateTransfer = async () => {
        if (!selectedItem || !selectedContract) return;

        let valid = true;

        // Validate all bill dates
        let billDatesForApi: number[] = [];
        let billDates: Date[] = [];
        if (transferBillDateMultipleFieldRef.current) {
            valid = transferBillDateMultipleFieldRef.current.validate();
            billDatesForApi =
                transferBillDateMultipleFieldRef.current.billDatesForApi;
            billDates = transferBillDateMultipleFieldRef.current.billDates;
        }

        if (!valid || billDates.length === 0) return;

        const validReceiverFields: boolean[] = [];
        if (paymentReceiversFieldsetRefs.current)
            paymentReceiversFieldsetRefs.current.forEach(
                (paymentReceiversFieldsetRef) => {
                    if (paymentReceiversFieldsetRef.current) {
                        validReceiverFields.push(
                            paymentReceiversFieldsetRef.current.validate()
                        );
                    }
                }
            );
        if (!validReceiverFields.every((valid: boolean) => valid)) return;

        let tagIds: number[] = [];
        if (tagsInputRef.current) {
            tagIds = tagsInputRef.current.getSelectedTagsIds();
        }

        if (!valid) return;

        // Request wallet signature
        setFormEnabled(false);
        setButtonEnabled(false);

        workingNotice.current = addNotification({
            msg: `Creating your batch payment...`,
            expires: false,
        });

        let allTransferAreSigned = true;
        const newTransfers: any[] = [];
        await Promise.all(
            // Map payment receivers
            paymentReceivers.map(
                async (paymentReceiver: PaymentReceiver, index: number) => {
                    // Map bill dates
                    await Promise.all(
                        billDatesForApi.map(
                            async (billDateForApi: number, index: number) => {
                                if (!paymentReceiver.amount) return;

                                // Avoid scientific notation
                                const parsedAmountFixed = toFixed(
                                    parseFloat(paymentReceiver.amount)
                                );

                                // Use ethers.utils to get the correct numbers for API
                                const amountForApi = parseUnits(
                                    parsedAmountFixed,
                                    decimals
                                ).toString();

                                let signature: string = "";

                                const formattedDateForInvoice =
                                    formatDateTimeFromSeconds(billDateForApi);
                                const invoiceId = `${paymentReceiver.toAddressOrEnsDomain} - (${formattedDateForInvoice})`;

                                if (!delegatedSigning) {
                                    try {
                                        signature =
                                            await generateTransferSignatureToken(
                                                {
                                                    contractAddress:
                                                        selectedContract.address,
                                                    contractNetworkId:
                                                        selectedContract.networkId,
                                                    invoiceId: invoiceId,
                                                    fromAddress: fromAddress,
                                                    toAddress:
                                                        paymentReceiver.toAddress,
                                                    token:
                                                        selectedToken &&
                                                        selectedToken.address,
                                                    amount: amountForApi,
                                                    usd: usd,
                                                }
                                            );
                                    } catch (error) {
                                        enableForm();
                                        allTransferAreSigned = false;
                                    }
                                }

                                if (!delegatedSigning && !signature) {
                                    addNotification({
                                        msg: "There was an error while signing the transaction",
                                        type: NotificationType.ERROR,
                                    });
                                    enableForm();
                                    return;
                                }
                                newTransfers.push({
                                    signature,
                                    invoiceId: invoiceId,
                                    entityId: selectedItem.entityId,
                                    amount: amountForApi,
                                    usd,
                                    to: paymentReceiver.toAddress,
                                    from: fromAddress,
                                    itemId: selectedItemId,
                                    token:
                                        selectedToken && selectedToken.address,
                                    billDate: billDateForApi,
                                    networkId: selectedContract.networkId,
                                    tagIds,
                                    notes: paymentReceiver.notes,
                                });
                            }
                        )
                    );
                }
            )
        );

        if (!allTransferAreSigned) {
            return;
        }

        const response = await fetch(
            `${apiServerUrl}/api/v1/company/transfers`,
            {
                method: `POST`,
                headers: {
                    Authorization: getSessionToken(),
                    "Content-Type": "application/json",
                    "entity-id": getEntityId(),
                },
                body: JSON.stringify(newTransfers),
            }
        );

        await response.json();
        const successFullResponse = response.ok && response.status === 200;

        if (successFullResponse) {
            removeWorkingNotice();
            onFormSuccess();
        } else {
            addNotification({
                msg: `There was an error while creating the ${
                    newTransfers.length === 1 ? "invoice" : "invoices"
                }`,
                type: NotificationType.ERROR,
            });
            enableForm();
        }
    };

    // 4. Disable Button until we have a valid item/agreement/token
    useEffect(() => {
        const validPaymentDetails =
            fromAddress.length > 0 && tokenAddress.length > 0;
        const hasSigner = delegatedSigning;

        setButtonEnabled(validPaymentDetails && hasSigner);
    }, [selectedItem, fromAddress, tokenAddress, delegatedSigning]);

    // Form: Item select options
    const itemOptions = paymentItems.map((item) => {
        const showType = item.name !== item.type;
        return {
            label: showType ? `${item.name} - (${item.type})` : item.name,
            value: item.id,
        };
    });

    const selectedItemAgreementsUnique = useMemo(() => {
        return (
            (selectedItemAgreementsOnNetwork && [
                ...new Map(
                    selectedItemAgreementsOnNetwork.map((a) => [
                        a.sender.wallet,
                        a,
                    ])
                ).values(),
            ]) ||
            []
        );
    }, [selectedItemAgreementsOnNetwork]);

    const authorizedWallets: Promise<SelectOption[]> = useMemo(() => {
        return Promise.all(
            selectedItemAgreementsUnique?.map(
                async (a: CompanyAgreementResponse) => {
                    let ensName;
                    try {
                        ensName = await getEnsRecord(a.sender.wallet)?.name;
                    } catch (e: any) {}

                    return {
                        label: `${
                            ensName || shortenAddress(a.sender.wallet)
                        } - ${a.sender.email}`,
                        value: a.sender.wallet,
                    };
                }
            ) || []
        );
    }, [selectedItemAgreementsUnique, getEnsRecord]);

    const tokenOptions = selectedItemTokens.map((token) => {
        return {
            label: token.symbol,
            value: token.address,
        };
    });

    const networkOptions = supportedNetworks.map((network) => {
        return {
            label: firstToUpper(network.name),
            value: network.id,
        };
    });

    // To let the user know they're about to sign a lot of transactions
    const walletTransactionsCount = paymentReceivers.length * billDatesCount;

    // Reset to defaultAddress when selectedItem change
    useEffect(() => {
        setFromAddress(defaultFromAddress);
    }, [defaultFromAddress, selectedItem?.id, selectedContract?.address]);

    const [authorizations, setAuthorizations] = useState<Authorization[]>([]);

    const getAuthorization = useCallback(
        async (token: GeneralTokenDetailsResponse, walletAddress: string) => {
            let blankAuthorization = {
                token: token,
                allowance: 0,
                balance: 0,
                rate: 0,
            };

            if (
                !selectedContract?.address ||
                !selectedNetwork?.hexId ||
                !selectedNetworkData?.rpcUrl
            ) {
                return blankAuthorization;
            }

            let authorization: Authorization;
            try {
                const allowance = Number(
                    formatUnits(
                        await getTokenAllowance({
                            tokenAddress: token.address,
                            walletAddress,
                            networkId: selectedNetworkData.networkId,
                            contractAddress: selectedContract.address,
                        }),
                        token.decimals
                    )
                );

                const walletAddressBalance = Number(
                    await getTokenBalance({
                        tokenAddress: token.address,
                        walletAddress,
                        networkId: selectedNetworkData.networkId,
                    })
                );

                const rate = getTokenRate(token)?.rate;
                authorization = {
                    token: token,
                    allowance: allowance || 0,
                    balance: walletAddressBalance || 0,
                    rate: rate,
                };
                return authorization;
            } catch (error) {
                authorization = {
                    token: token,
                    allowance: 0,
                    balance: 0,
                    rate: 0,
                };
            }

            return authorization;
        },
        [
            getTokenBalance,
            getTokenAllowance,
            getTokenRate,
            selectedContract,
            selectedNetwork,
            selectedNetworkData,
        ]
    );

    const updateAuthorizations = useCallback(async () => {
        const tokensAuthorizations: Authorization[] = await Promise.all(
            selectedItemTokens.map(async (token) => {
                const authorization: Authorization = await getAuthorization(
                    token,
                    fromAddress
                );
                return authorization;
            })
        );
        setAuthorizations(tokensAuthorizations);
    }, [fromAddress, getAuthorization, selectedItemTokens]);

    useEffect(() => {
        if (!fromAddress || !selectedNetwork?.hexId) {
            return setAuthorizations([]);
        }

        updateAuthorizations();
    }, [fromAddress, selectedNetwork?.hexId, updateAuthorizations]);

    const allowanceOnSuccess = useCallback(async () => {
        // Update the from address to the one the user has updated
        await updateAuthorizations();
        await getCompanyAgreementsRefetch();

        if (!walletConnected?.address) return;

        if (networkId === networkConnected?.id) {
            setFromAddress(walletConnected.proxyFor || walletConnected.address);
        }
    }, [
        walletConnected?.proxyFor,
        walletConnected?.address,
        networkConnected?.id,
        networkId,
        updateAuthorizations,
        getCompanyAgreementsRefetch,
    ]);

    const openAllowanceModal = useCallback(() => {
        if (!selectedEntity || !selectedItem) return;
        openModal(
            <AllowanceNewWallet
                entity={selectedEntity}
                item={selectedItem}
                setCanCloseModal={setCanCloseModal}
                onSuccess={allowanceOnSuccess}
            />,
            "Wallet authorization",
            "Connect and manage your authorized wallets"
        );
    }, [
        allowanceOnSuccess,
        openModal,
        selectedEntity,
        selectedItem,
        setCanCloseModal,
    ]);

    useEffect(() => {
        if (searchParams.has("allowance")) {
            searchParams.delete("allowance");
            openAllowanceModal();
        }
    }, [searchParams, openAllowanceModal]);

    // TOTAL FEES (SINGLE PAYMENT) ------------------------------------------
    // ! There is now a more intuitive way to calculate the fees, see `utils/fees.ts` for usage

    const feePerPayment = selectedEntity?.fees?.find(
        ({ type, networkId }) =>
            type === FeeConfigurationType.Outbound &&
            networkId === selectedNetwork?.id
    );
    const feePerPaymentAmount = feePerPayment?.amount || 0;
    const feePerPaymentAmountType = feePerPayment?.amountType as AmountType;

    // TOTAL AMOUNT (SINGLE DATE) ------------------------------------------

    let paymentTotalSingleDate = BigInt(0);
    let feeTotalSingleDate = BigInt(0);
    paymentReceivers.forEach((paymentReceiver) => {
        if (paymentReceiver.amount && parseFloat(paymentReceiver.amount) > 0) {
            // Add amount
            const amount = parseFloat(paymentReceiver.amount).toFixed(decimals);
            paymentTotalSingleDate =
                paymentTotalSingleDate + parseUnits(amount, decimals);

            // Add fees
            if (feePerPayment) {
                // NOTE: We may get into trouble here if the 3rd digit of percentage is non-zero
                feeTotalSingleDate =
                    feePerPaymentAmountType === AmountType.Fiat
                        ? feeTotalSingleDate + BigInt(feePerPaymentAmount)
                        : feePerPaymentAmountType === AmountType.Percent
                          ? BigInt(
                                ((toNumber(paymentReceiver.amount) *
                                    feePerPaymentAmount) /
                                    10 ** PERCENTAGE_ORDER_OF_MAGNITUDE) *
                                    DOLLAR_SCALE_FACTOR
                            )
                          : BigInt(0);
            }
        }
    });

    // Convert fees to token if not in USD
    let problemGettingRate = false;
    if (
        !usd &&
        selectedToken &&
        feePerPayment &&
        feePerPaymentAmountType === AmountType.Fiat
    ) {
        const tokenRate = getTokenRate(selectedToken)?.rate;

        if (tokenRate === undefined) {
            problemGettingRate = true;
            feeTotalSingleDate = BigInt(0);
        } else
            feeTotalSingleDate = BigInt(
                undecimalizeNumber(
                    convertCentsToToken(Number(feeTotalSingleDate), tokenRate),
                    selectedToken.decimals
                )
            );
    }

    const paymentTotalSingleDateFormatted = formatUnitsWithPrecision(
        paymentTotalSingleDate,
        decimals,
        decimals
    );

    const feeTotalSingleDateFormatted = formatUnitsWithPrecision(
        feeTotalSingleDate,
        decimals,
        decimals
    );

    // TOTAL AMOUNT (SINGLE PAYMENT) ------------------------------------------

    const paymentPlusFeesSingleDate =
        paymentTotalSingleDate + feeTotalSingleDate;

    const paymentPlusFeesSingleDateFormatted = formatUnitsWithPrecision(
        paymentPlusFeesSingleDate,
        decimals,
        decimals
    );

    // SUBTOTAL AMOUNT (ALL PAYMENTS) ------------------------------------------

    const paymentTotalMultipleDates =
        paymentTotalSingleDate * BigInt(billDatesCount);

    const paymentTotalMultipleDatesFormatted = formatUnitsWithPrecision(
        paymentTotalMultipleDates,
        decimals,
        decimals
    );

    // TOTAL FEES (ALL PAYMENTS) ------------------------------------------

    const feeTotalMultipleDates = feeTotalSingleDate * BigInt(billDatesCount);

    const feeTotalMultipleDatesFormatted = formatUnitsWithPrecision(
        feeTotalMultipleDates,
        decimals,
        decimals
    );

    // GRAND TOTAL ------------------------------------------

    const paymentPlusFeesMultipleDates: bigint =
        paymentTotalMultipleDates + feeTotalMultipleDates;

    const paymentPlusFeesMultipleDatesFormatted = formatUnitsWithPrecision(
        paymentPlusFeesMultipleDates,
        decimals,
        decimals
    );

    // SUBTOTAL LINE ITEMS ------------------------------------------

    const noRateAndIsFiat =
        problemGettingRate && feePerPaymentAmountType === AmountType.Fiat;
    const backupFeeRate = `${toDollar(feePerPaymentAmount)} in ${
        selectedToken?.symbol
    }`;
    const totalLineItems: DescriptionListItem[] = [];

    if (feeTotalSingleDate > 0 || noRateAndIsFiat) {
        totalLineItems.push({
            term: "Outbound payment total",
            definition: (
                <>
                    {billDatesCount > 1 && (
                        <small style={{ marginInlineEnd: `1rem` }}>
                            (
                            {usd
                                ? `$${paymentTotalSingleDateFormatted}`
                                : `${paymentTotalSingleDateFormatted} ${selectedToken?.symbol}`}{" "}
                            &times; {billDatesCount} payment dates)
                        </small>
                    )}
                    {usd
                        ? `$${paymentTotalMultipleDatesFormatted}`
                        : `${paymentTotalMultipleDatesFormatted} ${selectedToken?.symbol}`}
                </>
            ),
            style: S.LineItem,
        });
        totalLineItems.push({
            term: "Scheduling fee",
            definition: (
                <>
                    {noRateAndIsFiat ? (
                        <>
                            <sup>*</sup>
                            {backupFeeRate}
                            <br />
                            <small>
                                (Had trouble getting an exchange rate for{" "}
                                {selectedToken?.symbol})
                            </small>
                        </>
                    ) : (
                        <>
                            {billDatesCount > 1 && (
                                <small style={{ marginInlineEnd: `1rem` }}>
                                    (
                                    {usd
                                        ? `$${feeTotalSingleDateFormatted}`
                                        : `${feeTotalSingleDateFormatted} ${selectedToken?.symbol}`}{" "}
                                    &times; {billDatesCount} payment dates)
                                </small>
                            )}
                            {usd
                                ? `$${feeTotalMultipleDatesFormatted}`
                                : `${feeTotalMultipleDatesFormatted} ${selectedToken?.symbol}`}
                        </>
                    )}
                </>
            ),
            style: S.LineItem,
        });
    }

    ////////////////////////////////

    const selectedTokenAuthorization =
        selectedToken && fromAddress
            ? authorizations.find(
                  ({ token }) =>
                      token.address === selectedToken.address &&
                      token.networkId === selectedToken.networkId
              )
            : undefined;

    const [fromAddressOptions, setFromAddressOptions] = useState<
        SelectOption[]
    >([
        {
            label: "+ New wallet",
            value: "",
        },
    ]);

    useEffect(() => {
        authorizedWallets.then((wallets) => {
            setFromAddressOptions([
                ...wallets,
                {
                    label: "+ New wallet",
                    value: "",
                },
            ]);
        });
    }, [authorizedWallets]);

    const canCreateTransfers = paymentItems.length > 0;
    const maxTransfersPerRequest = 120;

    // If a user was to add a receiver
    const additionalPaymentReceiverTransfersCount =
        (paymentReceivers.length + 1) * billDatesCount;

    // If a user was to add a bill date
    const additionalBillDateTransfersCount =
        paymentReceivers.length * (billDatesCount + 1);

    const paymentReceiverWillReachTransferLimit =
        additionalPaymentReceiverTransfersCount > maxTransfersPerRequest;
    const billDateWillReachTransferLimit =
        additionalBillDateTransfersCount > maxTransfersPerRequest;

    return (
        <>
            {canCreateTransfers ? (
                <S.BatchPayForm preventEnterKeySubmission>
                    <SubSection
                        title="Payment"
                        separator="bottom"
                        spacing={[Spacing.none, Spacing.lg]}
                    >
                        <S.GridColumn col={3}>
                            {itemOptions.length > 1 && (
                                <Field>
                                    <Label htmlFor="itemId">Type</Label>
                                    <Select
                                        name="itemId"
                                        disabled={!formEnabled}
                                        value={selectedItemId || ""}
                                        onChange={(event) =>
                                            setSelectedItemId(
                                                event.target.value
                                            )
                                        }
                                        options={itemOptions}
                                    />
                                </Field>
                            )}

                            {networkOptions &&
                                networkOptions.length > 0 &&
                                selectedNetworkData && (
                                    <Field>
                                        <Label htmlFor="networkId">
                                            Network
                                        </Label>
                                        <SelectWithIcon
                                            selectProps={{
                                                value: networkId || "",
                                                disabled: !formEnabled,
                                                onChange: (event) =>
                                                    setNetworkId(
                                                        parseInt(
                                                            event.target.value
                                                        )
                                                    ),
                                                options: networkOptions,
                                                name: "networkId",
                                            }}
                                            icon={{
                                                src: selectedNetworkData.icon,
                                                alt: `${selectedNetworkData.label} logo`,
                                            }}
                                        />
                                    </Field>
                                )}
                            <Field>
                                <Label htmlFor="tags">Tags</Label>
                                <TagsInput ref={tagsInputRef} name="tags" />
                            </Field>
                        </S.GridColumn>
                        <S.GridColumn col={1}>
                            <Field>
                                <Label htmlFor="billDate">
                                    Payment{" "}
                                    {billDatesCount === 1 ? "date" : "dates"}
                                </Label>
                                <TransferBillDateMultipleField
                                    disabled={!formEnabled}
                                    ref={transferBillDateMultipleFieldRef}
                                    billDateWillReachTransferLimit={
                                        billDateWillReachTransferLimit
                                    }
                                    maxTransfersPerRequest={
                                        maxTransfersPerRequest
                                    }
                                    onChangeBillDates={(billDates: Date[]) =>
                                        setBillDatesCount(billDates.length)
                                    }
                                />
                            </Field>
                        </S.GridColumn>
                    </SubSection>

                    <SubSection
                        title="Sender"
                        separator="bottom"
                        spacing={[Spacing.none, Spacing.lg]}
                    >
                        <S.GridColumn col={3}>
                            <S.WalletField>
                                <Label htmlFor="fromAddress" required>
                                    Wallet
                                </Label>
                                <Select
                                    name="fromAddress"
                                    disabled={!formEnabled}
                                    value={fromAddress}
                                    onChange={(event) =>
                                        setFromAddress(event.target.value)
                                    }
                                    options={fromAddressOptions}
                                />
                                {!fromAddress && (
                                    <Hint>
                                        You'll authorize this wallet at the end
                                    </Hint>
                                )}
                                {fromAddress && selectedTokenAuthorization && (
                                    <Hint>
                                        Need to increase your allowance? You can
                                        do so{" "}
                                        <Anchor onClick={openAllowanceModal}>
                                            here
                                        </Anchor>
                                        .
                                    </Hint>
                                )}
                            </S.WalletField>
                        </S.GridColumn>
                    </SubSection>

                    <SubSection
                        title={
                            paymentReceivers.length === 1
                                ? "Receiver"
                                : "Receivers"
                        }
                        // separator="bottom"
                        spacing={[Spacing.none, Spacing.lg]}
                    >
                        <S.GridColumn col={3}>
                            <Field>
                                <Label htmlFor="tokenAddress" required>
                                    Pay with
                                </Label>
                                {selectedToken && selectedNetwork && (
                                    <SelectWithIcon
                                        selectProps={{
                                            value: selectedToken.address,
                                            disabled: !formEnabled,
                                            onChange: (event) =>
                                                setTokenAddress(
                                                    event.target.value
                                                ),
                                            options: tokenOptions,
                                            name: "token",
                                        }}
                                        icon={{
                                            src: selectedToken.logoUrl,
                                            alt: `${selectedToken.name} logo`,
                                        }}
                                    />
                                )}
                            </Field>
                            <S.CheckboxField>
                                <Label htmlFor="usd">
                                    <Checkbox
                                        name="usd"
                                        disabled={!formEnabled}
                                        checked={usd}
                                        onChange={(event) =>
                                            setUsd(event.target.checked)
                                        }
                                    />
                                    Amount in USD
                                </Label>
                            </S.CheckboxField>
                        </S.GridColumn>

                        <article>
                            {selectedToken &&
                                selectedItem &&
                                selectedNetwork &&
                                paymentReceivers.map(
                                    (
                                        paymentReceiver: PaymentReceiver,
                                        index: number
                                    ) => {
                                        return (
                                            <S.PaymentReceiverContainer
                                                key={`payment-receiver-fieldset-${index}`}
                                            >
                                                <PaymentReceiverFieldset
                                                    index={index}
                                                    paymentReceiver={
                                                        paymentReceiver
                                                    }
                                                    onChange={(
                                                        paymentReceiverUpdates: PaymentReceiver
                                                    ) =>
                                                        updatePaymentReceivers(
                                                            paymentReceiverUpdates,
                                                            index
                                                        )
                                                    }
                                                    formEnabled={formEnabled}
                                                    usd={usd}
                                                    selectedToken={
                                                        selectedToken
                                                    }
                                                    networkHexId={
                                                        selectedNetwork.hexId
                                                    }
                                                    ref={
                                                        paymentReceiversFieldsetRefs
                                                            .current[index]
                                                    }
                                                />
                                                <S.RemovePaymentReceiver>
                                                    {index > 0 && (
                                                        <Anchor
                                                            href="#remove-receiver"
                                                            disabled={
                                                                !formEnabled
                                                            }
                                                            underlined={false}
                                                            onClick={() =>
                                                                removePaymentReceiver(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            Remove
                                                        </Anchor>
                                                    )}
                                                </S.RemovePaymentReceiver>
                                            </S.PaymentReceiverContainer>
                                        );
                                    }
                                )}

                            <S.Footer>
                                {paymentReceiverWillReachTransferLimit ? (
                                    <p>
                                        You can submit a maximum of{" "}
                                        {maxTransfersPerRequest} transfers
                                    </p>
                                ) : (
                                    <Button
                                        variant={ButtonVariants.PrimaryOutlined}
                                        size={ButtonSizes.Small}
                                        onClick={addPaymentReceiver}
                                        type="button"
                                        disabled={!formEnabled}
                                    >
                                        Add a receiver
                                    </Button>
                                )}
                            </S.Footer>
                        </article>
                    </SubSection>
                    <SubSection
                        separator="bottom"
                        spacing={[Spacing.none, Spacing.lg]}
                    >
                        {!!totalLineItems.length && (
                            <S.Subtotals items={totalLineItems}></S.Subtotals>
                        )}

                        {paymentTotalSingleDateFormatted && (
                            <S.PaymentReceiverTotalContainer>
                                <strong>Total</strong>
                                <S.PaymentReceiverTotalRight>
                                    <p>
                                        {billDatesCount > 1 && (
                                            <small
                                                style={{
                                                    marginInlineEnd: `1rem`,
                                                }}
                                            >
                                                (
                                                {usd
                                                    ? `$${paymentPlusFeesSingleDateFormatted}`
                                                    : `${paymentPlusFeesSingleDateFormatted} ${selectedToken?.symbol}`}{" "}
                                                &times; {billDatesCount} payment
                                                dates)
                                            </small>
                                        )}
                                        <b>
                                            {usd
                                                ? `$${paymentPlusFeesMultipleDatesFormatted}`
                                                : `${paymentPlusFeesMultipleDatesFormatted} ${selectedToken?.symbol}`}
                                            {noRateAndIsFiat &&
                                                ` + ${backupFeeRate}`}
                                        </b>
                                    </p>
                                </S.PaymentReceiverTotalRight>
                            </S.PaymentReceiverTotalContainer>
                        )}
                        {selectedTokenAuthorization && (
                            <>
                                <AuthorizationInformation
                                    authorization={selectedTokenAuthorization}
                                    usd={usd}
                                />
                            </>
                        )}
                    </SubSection>

                    <SubSection title="Approve">
                        <SubmitSteps
                            authorization={selectedTokenAuthorization}
                            fromAddress={fromAddress}
                            network={selectedNetwork?.hexId}
                            handleAuthorizeWallet={openAllowanceModal}
                            delegatedSigning={delegatedSigning}
                            buttonEnabled={buttonEnabled}
                            handleCreateTransfer={handleCreateTransfer}
                            walletTransactionsCount={walletTransactionsCount}
                        />
                    </SubSection>
                </S.BatchPayForm>
            ) : (
                <ErrorMessage
                    msg="There is no active subscription or one-time purchase
                        to invoice"
                >
                    <p>
                        Head to{" "}
                        <NavLink to={`/subscriptions`}>subscriptions</NavLink>{" "}
                        or <NavLink to={`/payments`}>one-time purchase</NavLink>{" "}
                        for details.
                    </p>
                </ErrorMessage>
            )}
        </>
    );
};

export default BatchPayForm;
