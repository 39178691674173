import {
    ReactNode,
    RefObject,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { CheckoutContractResponse } from "api/types/checkout";
import { CheckoutNetwork } from "checkout/types";
import { ExchangableToken } from "types/common";
import {
    calculateTokenPricedMinimumAllowance,
    calculateTokenPricedMinimumBalance,
    calculateTokenPricedSuggestedAllowance,
} from "utils/checkout";
import { bnToCoin, toCoin, toDollar } from "utils/financial";
import { toNetworkHex } from "utils/addresses";
import { WrapperToken } from "hooks/useWrappableTokens";
import { useCheckoutData } from "checkout/context/CheckoutData";
import { useAuthorizationToken } from "checkout/hooks/useAuthorizationToken";
import {
    PreviouslySubscribed,
    useCheckIfSubscribed,
} from "checkout/hooks/useCheckIfSubscribed";
import { useCheckoutAllowance } from "checkout/hooks/useCheckoutAllowance";
import { useCheckoutSubscribe } from "checkout/hooks/useCheckoutSubscribe";
import useManageDetailsPanels from "checkout/hooks/useManageDetailsPanels";
import {
    CheckoutEvents,
    CheckoutSteps,
    useCheckoutStateMachine,
} from "checkout/hooks/useCheckoutStateMachine";
import { convertCentsToToken } from "utils/exchangeRates";
import { useWallet } from "context/Wallet";
import * as Sentry from "@sentry/react";

const convertIfRateAvailable = (amount: number, rate: number | undefined) =>
    rate ? convertCentsToToken(amount, rate) : 0;

interface CheckoutFormProps {
    children: ReactNode;
}

interface CheckoutFormValue {
    checkoutFormStep: CheckoutSteps;
    network?: CheckoutNetwork;
    contract?: CheckoutContractResponse;
    token?: ExchangableToken;
    networkFieldRef: RefObject<NetworkFieldRef>;
    tokenFieldRef: RefObject<TokenFieldRef>;
    allowanceFieldRef: RefObject<AllowanceFieldRef>;
    updateNetwork: (id: string) => void;
    updateToken: (addr: string) => void;
    confirmPayment: () => void;
    confirmContact: () => void;
    confirmApprove: () => void;
    createNewAuthToken: (expiration?: Date) => Promise<string>;
    authToken: string;
    sendAllowance: (
        allowanceAmount?: string,
        token?: ExchangableToken,
        contract?: CheckoutContractResponse
    ) => Promise<any>;
    hasMinimumAllowance: boolean;
    subscribed: PreviouslySubscribed | false;
    tokenTotalWithoutDiscount: bigint | null;
    tokenTotalDueToday: bigint | null;
    isTokenPricedCheckout: boolean;
    nativeToken?: WrapperToken;
    tokenBalance: number;
    loadingBalance: boolean;
    wrapperBalance: number;
    updateBalances: () => Promise<void>;
    hasSufficientBalance: boolean;
    currentAllowance: number;
    tokenMinimumAllowance: number;
    tokenSuggestedAllowance: number;
    tokenMinimumBalance: number;
    subscribe: (billDate?: number) => Promise<void>;
    subscribing: boolean;
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    setEmailBeingEdited: React.Dispatch<React.SetStateAction<boolean>>;
}

const CheckoutFormContext = createContext<CheckoutFormValue>(
    {} as CheckoutFormValue
);

const CheckoutFormProvider = ({ children }: CheckoutFormProps) => {
    const { walletConnected, getTokenBalance } = useWallet();
    const {
        tokens,
        wrappableTokens,
        networks,
        contracts,
        mainItem,
        isOneTimePayment,
        getTotalsByToken,
        usdTotalDueToday,
        items,
        entity,
        setCheckoutComplete,
        queryParams,
        minimumBalance,
        minimumAllowance,
        suggestedAllowance,
        totalPricePerCommonToken,
        externalEmail,
    } = useCheckoutData();

    const { state: checkoutState, dispatch } = useCheckoutStateMachine();
    useManageDetailsPanels(checkoutState);
    const { authToken, createNewAuthToken } = useAuthorizationToken();
    const [network, setNetwork] = useState<CheckoutNetwork>();
    const [contract, setContract] = useState<CheckoutContractResponse>();
    const [token, setToken] = useState<ExchangableToken>();
    const [isTokenPricedCheckout, setIsTokenPricedCheckout] =
        useState<boolean>(false);
    const [nativeToken, setNativeToken] = useState<WrapperToken | undefined>();

    const [email, setEmail] = useState<string>(externalEmail || ``);
    const [emailBeingEdited, setEmailBeingEdited] = useState<boolean>(false);
    const [subscribing, setSubscribing] = useState<boolean>(false);

    const networkFieldRef = useRef<NetworkFieldRef>(null);
    const tokenFieldRef = useRef<TokenFieldRef>(null);
    const allowanceFieldRef = useRef<AllowanceFieldRef>(null);

    const tokenTotalWithoutDiscount =
        (isTokenPricedCheckout && token && getTotalsByToken(token).total) ||
        null;
    const tokenTotalDueToday =
        (isTokenPricedCheckout &&
            token &&
            getTotalsByToken(token).totalDueToday) ||
        null;

    // Convert minimumBalance, minimumAllowance, and suggestedAllowance to token amounts
    const tokenMinimumBalance =
        isTokenPricedCheckout && token
            ? calculateTokenPricedMinimumBalance(tokenTotalDueToday, token)
            : convertIfRateAvailable(minimumBalance, token?.exchange.rate);

    const tokenMinimumAllowance =
        isTokenPricedCheckout && token
            ? calculateTokenPricedMinimumAllowance(
                  tokenTotalWithoutDiscount,
                  token
              )
            : convertIfRateAvailable(minimumAllowance, token?.exchange.rate);

    const tokenSuggestedAllowance =
        isTokenPricedCheckout && token
            ? calculateTokenPricedSuggestedAllowance(items, token)
            : convertIfRateAvailable(suggestedAllowance, token?.exchange.rate);

    // Check if the user has the minimum allowance at the very least
    const {
        hasMinimumAllowance,
        updateHasAllowance,
        sendAllowance,
        currentAllowance,
    } = useCheckoutAllowance(tokenMinimumAllowance);

    const { subscribed: previouslySubscribed } = useCheckIfSubscribed(
        contract?.address || ``,
        mainItem?.id || ``,
        network
    );

    const subscribed =
        !isOneTimePayment && !!previouslySubscribed
            ? previouslySubscribed
            : false;

    const updateNetwork = useCallback(
        (id: string) => {
            const foundNetwork = networks.find(({ hexId }) => hexId === id);
            const foundContract = contracts.find(
                ({ networkId }) => networkId === foundNetwork?.id
            );

            setNetwork(foundNetwork);
            setContract(foundContract);
        },
        [networks, contracts]
    );

    const updateToken = useCallback(
        async (addr: string) => {
            const foundToken = tokens.find(
                ({ address, networkId }) =>
                    address.toLowerCase() === addr.toLowerCase() &&
                    toNetworkHex(networkId) === network?.hexId
            );

            setToken(foundToken);
            setNativeToken(
                foundToken
                    ? wrappableTokens.find(
                          (wToken) =>
                              wToken.wrapsTo === foundToken.symbol &&
                              wToken.networkId === foundToken.networkId
                      )
                    : undefined
            );
            setIsTokenPricedCheckout(
                totalPricePerCommonToken.some(({ address, network }) => {
                    return (
                        address === foundToken?.address &&
                        network === foundToken?.networkId
                    );
                })
            );
        },
        [tokens, network, wrappableTokens, totalPricePerCommonToken]
    );

    const [tokenBalance, setTokenBalance] = useState<number>(0);
    const [loadingBalance, setLoadingBalances] = useState<boolean>(false);
    const [wrapperBalance, setWrapperTokenBalance] = useState<number>(0);

    // We have enough token (e.g. WETH) to cover payment
    const hasSufficientBalance = !!token && tokenBalance >= tokenMinimumBalance;

    const updateBalances = useCallback(async () => {
        if (!token) return;
        if (!walletConnected) return;
        setLoadingBalances(true);

        try {
            await getTokenBalance({
                tokenSymbol: token.symbol,
                tokenAddress: token.address,
                force: true,
            })
                .then((balance: string) => {
                    setTokenBalance(Number(balance));
                })
                .catch(() => {
                    setTokenBalance(0);
                });

            if (!nativeToken) {
                setWrapperTokenBalance(0);
            } else {
                await getTokenBalance({ force: true })
                    .then((balance: string) => {
                        setWrapperTokenBalance(Number(balance));
                    })
                    .catch(() => {
                        setWrapperTokenBalance(0);
                    });
            }
        } catch (error) {
            // [ ] Update: This is not likely an issue anymore since Dynamic, test and cleanup
            /* RP: We have to catch something here, because getTokenBalance throws when changing networks
            -----
            There seems to be an issue when switching networks. The issue is outlined below
            with `updateHasAllowance`, where the wrong token/contract combo is called This
            may be resolved by pulling useErc20 functions out of the hook so they don't force refresh */
        } finally {
            setLoadingBalances(false);
        }
    }, [token, getTokenBalance, nativeToken, walletConnected]);

    // (1) PAYMENT: If the token (slash, network), or wallet changes, reset the form
    useEffect(() => {
        Sentry.setTag(`wallet`, walletConnected?.address);
        Sentry.setTag(`safeInUse`, walletConnected?.proxyFor || false);
        dispatch({ type: CheckoutEvents.GO_TO_PAYMENT });

        // If a wallet is connected, and a token is selected, update balances
        if (!walletConnected?.address || !token) return;
        updateBalances().catch(() => {});
    }, [
        dispatch,
        updateBalances,
        walletConnected?.address,
        walletConnected?.proxyFor,
        token,
        nativeToken,
    ]);

    // (2) CONTACT: Payment token confirmed, get contact info
    const confirmPayment = useCallback(() => {
        dispatch({ type: CheckoutEvents.GO_TO_CONTACT });

        Sentry.setTag("network", network?.id);
        Sentry.setTag("token", token?.symbol);
    }, [dispatch, token, network]);

    // (3) APPROVE: Contact is complete, move to approve
    const confirmContact = useCallback(() => {
        dispatch({ type: CheckoutEvents.GO_TO_APPROVE });
    }, [dispatch]);

    // Confirm checkout/complete
    const confirmApprove = useCallback(() => {
        dispatch({ type: CheckoutEvents.GO_TO_COMPLETE });
    }, [dispatch]);

    useEffect(() => {
        // If wallet balance changes (likely due to wallet change), go back to PAYMENT
        if (!hasSufficientBalance)
            dispatch({ type: CheckoutEvents.GO_TO_PAYMENT });
    }, [dispatch, hasSufficientBalance]);

    useEffect(() => {
        // If user edits email after passing CONTACT step, go back to CONTACT
        if (emailBeingEdited && checkoutState.step > CheckoutSteps.CONTACT)
            dispatch({ type: CheckoutEvents.GO_TO_CONTACT });
    }, [dispatch, emailBeingEdited, checkoutState.step]);

    useEffect(() => {
        if (!walletConnected?.address || !token || !contract) return;

        // [ ] Check if this is still true given new functionality
        /* If the user switches networks, this callback runs 3 times:
            - First, signer/rpc updates, which triggers the first (unnecessary) call with the old token/contract (bc getContract updated)
            - Then the `network` changes, which causes another call with mismatching token/network pair (this will throw an Error)
            - Then the `token` changes, which triggers another call, which is correct and successful
        */
        updateHasAllowance(token, contract).catch((error) => {});
    }, [
        updateHasAllowance,
        walletConnected?.proxyFor,
        walletConnected?.address,
        token,
        contract,
    ]);

    const totalPaidToday: ReactNode = tokenTotalDueToday ? (
        <>
            <b>{bnToCoin(tokenTotalDueToday, token?.decimals)}</b>{" "}
            {token?.symbol}
        </>
    ) : (
        <>
            <b>
                {toCoin(
                    convertCentsToToken(
                        usdTotalDueToday ?? 0,
                        token?.exchange.rate || 1
                    )
                )}{" "}
                {token?.symbol}
            </b>{" "}
            <small>{toDollar(usdTotalDueToday ?? 0)}</small>
        </>
    );

    const { sendSubscribe } = useCheckoutSubscribe(
        totalPaidToday,
        hasMinimumAllowance,
        setCheckoutComplete
    );

    const subscribe = useCallback(
        async (billDate?: number) => {
            setSubscribing(true);
            await sendSubscribe(
                items.map(({ id }) => id),
                entity?.entityId || ``,
                authToken,
                token?.address,
                queryParams,
                email,
                billDate
            ).finally(() => setSubscribing(false));
        },
        [
            authToken,
            entity?.entityId,
            items,
            queryParams,
            sendSubscribe,
            token?.address,
            email,
        ]
    );

    return (
        <CheckoutFormContext.Provider
            value={{
                checkoutFormStep: checkoutState.step,
                network,
                contract,
                token,
                networkFieldRef,
                tokenFieldRef,
                allowanceFieldRef,
                updateNetwork,
                updateToken,
                confirmPayment,
                confirmContact,
                confirmApprove,
                createNewAuthToken,
                authToken,
                sendAllowance,
                hasMinimumAllowance,
                currentAllowance,
                subscribed,
                tokenTotalWithoutDiscount,
                tokenTotalDueToday,
                isTokenPricedCheckout,
                nativeToken,
                tokenBalance,
                loadingBalance,
                wrapperBalance,
                updateBalances,
                hasSufficientBalance,
                tokenMinimumAllowance,
                tokenSuggestedAllowance,
                tokenMinimumBalance,
                subscribe,
                subscribing,
                email,
                setEmail,
                setEmailBeingEdited,
            }}
        >
            {children}
        </CheckoutFormContext.Provider>
    );
};

const useCheckoutForm = (): CheckoutFormValue => {
    const context = useContext<CheckoutFormValue>(CheckoutFormContext);
    if (context === undefined) {
        throw new Error(
            `useCheckoutForm() must be used within a CheckoutFormProvider`
        );
    }
    return context;
};

export { CheckoutFormProvider, useCheckoutForm };
