import { useCallback, useEffect, useState } from "react";
import {
    useDynamicContext,
    useWalletConnectorEvent,
} from "@dynamic-labs/sdk-react-core";
import { AvailableNetwork } from "default-variables";
import { useAvailableNetworks } from "hooks/useAvailableNetworks";
import { toNetworkId, networkToHex } from "utils/addresses";
import { useNotificationQueue } from "context/NotificationQueue";
import { NotificationType } from "components/Notification";

export type SetConnectedNetworkProps = { networkId: number | string };

const useWalletNetwork = () => {
    const [network, setNetwork] = useState<AvailableNetwork | null>(null);
    const [isNetworkSetting, setIsNetworkSetting] = useState(false);
    const { addNotification } = useNotificationQueue();

    const { primaryWallet } = useDynamicContext();
    const { getNetworkById } = useAvailableNetworks();

    // Process change of network data
    const handleNetworkChange = useCallback(
        (networkId: number | string | undefined) => {
            // Don't assign a new object if the network is the same
            if (Number(networkId) === network?.id) return;
            if (!networkId) return setNetwork(null);

            try {
                const newNetwork = getNetworkById(networkToHex(networkId));
                if (!newNetwork) throw new Error(`Network is not available.`);
                setNetwork(newNetwork);
            } catch (error) {
                console.error(`Failed to set network.`, error);
                setNetwork(null);
            }
        },
        [network?.id, getNetworkById]
    );

    // Used to trigger a network change
    // [ ] Update this to reject with a message
    const setConnectedNetwork = useCallback(
        async ({ networkId }: SetConnectedNetworkProps) => {
            // Chain doesn't support network switching, ie, Solana
            if (!primaryWallet?.connector.supportsNetworkSwitching()) {
                handleNetworkChange(networkId);
                return true;
            }

            setIsNetworkSetting(true);

            try {
                await primaryWallet?.connector.switchNetwork({
                    networkChainId: toNetworkId(networkId),
                });
                return true;
            } catch (error: any) {
                console.error(`Failed to switch network`, error);

                // This should ideally be done at the point of the caller to setConnectedNetwork. Update when the reject is thrown back instead of returning a boolean
                if (
                    error.code === "network_switching_only_available_in_wallet"
                ) {
                    const newNetwork = getNetworkById(networkToHex(networkId));
                    addNotification({
                        msg: (
                            <>
                                {primaryWallet?.connector.name || `This wallet`}{" "}
                                does not support the{" "}
                                {newNetwork?.label || `connected`} network.
                                <br />
                                Please select a network supported by your
                                wallet.
                            </>
                        ),
                        type: NotificationType.WARNING,
                    });
                }

                return false;
            } finally {
                setIsNetworkSetting(false);
            }
        },
        [
            primaryWallet?.connector,
            handleNetworkChange,
            addNotification,
            getNetworkById,
        ]
    );

    // Setup network on walletConnector setup or change
    useEffect(() => {
        if (!primaryWallet?.connector) {
            setNetwork(null);
            return;
        }

        (async () => {
            // [ ] This call is happening more than it should, how else can we detect a network change?
            const networkId = await primaryWallet?.connector.getNetwork();
            handleNetworkChange(networkId);
        })();
    }, [primaryWallet?.connector, handleNetworkChange]);

    // Network has changed
    useWalletConnectorEvent(
        primaryWallet?.connector ? [primaryWallet?.connector] : [],
        "chainChange",
        ({ chain }) => {
            handleNetworkChange(chain);
        }
    );

    return { network, isNetworkSetting, setConnectedNetwork };
};

export default useWalletNetwork;
