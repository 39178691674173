import {
    Dispatch,
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin
import utc from "dayjs/plugin/utc.js";
import { getItemsUsdTotal } from "utils/checkout";
import { TokenExchangeDetailsResponse } from "api";
import {
    CheckoutContractResponse,
    CheckoutEntityResponse,
    CheckoutNetworkResponse,
    InvoiceDetailsResponse,
} from "api/types/checkout";
import { CheckoutItem, CheckoutNetwork } from "checkout/types";
import { ExchangableToken } from "types/common";
import { useFetchCheckout } from "checkout/hooks/useFetchCheckout";
import useWrappableTokens, { WrapperToken } from "hooks/useWrappableTokens";
import { CouponDetails, useCouponsWithDetails } from "./useCouponsWithDetails";
import { useCreateCheckoutItems } from "./useCreateCheckoutItems";
import { useMinimumBalance } from "./useMinimumBalance";
import { useMinimumAllowance } from "./useMinimumAllowance";
import { useSuggestedAllowance } from "./useSuggestedAllowance";
import { useExchangableTokens } from "hooks/useExchangableTokens";
import {
    CheckoutTokenPrice,
    useTotalPricePerCommonToken,
} from "checkout/context/CheckoutData/useTotalPricePerCommonToken";
import { useAvailableNetworks } from "hooks/useAvailableNetworks";
import * as Sentry from "@sentry/react";

dayjs.extend(utc);
dayjs.extend(timezone);

interface CheckoutDataProps {
    children: ReactNode;
    entityId?: string;
    itemId?: string;
    queryParams: CheckoutQueryParams;
}

export interface TotalDueLater {
    usd: number | null;
    tokens: {
        tokenAddress: string;
        network: number;
        amount: string;
    }[];
}

interface TotalDueLaterGroupedByDueDate {
    [key: number]: TotalDueLater;
}

interface CheckoutDataValue {
    fetchLoading: boolean;
    fetchError: string;
    fetchSuccess: boolean;
    isSearchByInvoice: boolean;
    checkoutComplete: CheckoutPostResponse | null;
    setCheckoutComplete: Dispatch<
        React.SetStateAction<CheckoutPostResponse | null>
    >;
    queryParams: CheckoutQueryParams;
    networks: CheckoutNetwork[];
    tokens: ExchangableToken[];
    wrappableTokens: WrapperToken[];
    items: CheckoutItem[];
    couponsWithDetails: CouponDetails[];
    contracts: CheckoutContractResponse[];
    entity: CheckoutEntityResponse | undefined;
    mainItem: CheckoutItem | undefined;
    isOneTimePayment: boolean;
    isAnyPriceVarious: boolean;
    hasFreeTrial: boolean;
    coupons: CouponResponse[];
    setCoupons: Dispatch<React.SetStateAction<CouponResponse[]>>;
    totalPricePerCommonToken: CheckoutTokenPrice[];
    getTotalsByToken: (token: ExchangableToken) => {
        total: bigint | undefined;
        totalWithDiscount: bigint | undefined;
        totalDueToday: bigint | undefined;
    };
    getTotalPriceByAddressAndNetwork: (
        address: string,
        network: number
    ) => CheckoutTokenPrice | undefined;
    usdTotalWithoutDiscount: number | null;
    usdTotalDueToday: number | null;
    isInvoicedCheckout: boolean;
    isExternalSubscription: boolean;
    invalidSubscriptionId: boolean;
    invalidCustomerId: boolean;
    totalDueLaterGroupedByDueDate: TotalDueLaterGroupedByDueDate;
    formErrorDisplay: string;
    minimumAllowance: number;
    suggestedAllowance: number;
    invoiceDetails?: InvoiceDetailsResponse | null;
    minimumBalance: number;
    dueDateForDisplay: string;
    externalEmail: string | null;
}

const CheckoutDataContext = createContext<CheckoutDataValue>(
    {} as CheckoutDataValue
);

const getUsdTotalDueToday = (
    invoiceDetails: InvoiceDetailsResponse | null | undefined,
    items: CheckoutItem[]
): number | null =>
    invoiceDetails?.amount ??
    getItemsUsdTotal({
        items: items.filter(({ dueDate }) => dueDate === 0),
        withDiscount: true,
    });

const getFormattedDueDate = (
    dueDate: number | undefined,
    timezone: string | undefined
): string => {
    if (!dueDate) return ``;

    let date = dayjs(dueDate * 1000).utc();

    if (timezone) {
        date = date.tz(timezone, true);
    }

    return date.toDate().toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        timeZone: timezone,
    });
};

const getIsCheckoutPriceVarious = (
    isExternalSubscription: boolean,
    invoiceDetails: InvoiceDetailsResponse | null | undefined,
    items: CheckoutItem[]
) => {
    // If it's a subscription, incorrect items may be present, so only check the invoice amount to determine if the price is various
    if (isExternalSubscription) {
        return invoiceDetails?.amount === 0;
    }
    return items.some(({ isVariablePricing }) => isVariablePricing);
};

const CheckoutDataProvider = ({
    children,
    entityId,
    itemId,
    queryParams,
}: CheckoutDataProps) => {
    const [checkoutComplete, setCheckoutComplete] =
        useState<CheckoutPostResponse | null>(null);

    // Fetch all the required data
    const {
        data: {
            tokens,
            entity,
            items,
            contracts,
            networks,
            invoiceDetails,
            validExternalSubscription,
            validExternalCustomer,
            email,
        } = {},
        loading,
        error,
        success,
        formErrorDisplay,
    } = useFetchCheckout({
        entityId,
        itemId,
        queryParams,
    });

    const isInvoicedCheckout = !!queryParams.invoiceId && !!invoiceDetails;
    const isExternalSubscription =
        !!validExternalSubscription && !!invoiceDetails;
    const invalidSubscriptionId = validExternalSubscription === false;
    const invalidCustomerId = validExternalCustomer === false;

    const [coupons, setCoupons] = useState<CouponResponse[]>([]);

    // "Augment" Coupon response by adding formatted attributes and state
    const couponsWithDetails: CouponDetails[] = useCouponsWithDetails(coupons);

    const { exchangableTokens: checkoutTokens, tokenError } =
        useExchangableTokens(tokens);
    const tokensAreProcessed = tokens?.length === checkoutTokens?.length;

    const wrappableTokens =
        useWrappableTokens<TokenExchangeDetailsResponse>(tokens);

    const checkoutItems: CheckoutItem[] = useCreateCheckoutItems(
        items,
        couponsWithDetails,
        tokensAreProcessed ? checkoutTokens : undefined,
        queryParams.billDate
    );

    // Isolate the main item
    const [mainItem, setMainItem] = useState<CheckoutItem | undefined>(
        checkoutItems.find(({ id }) => id === itemId) || checkoutItems?.at(0)
    );

    const hasFreeTrial = (mainItem?.initialOffset || 0) > 0;

    const dueDateForDisplay = getFormattedDueDate(
        invoiceDetails?.dueDate,
        invoiceDetails?.timezone
    );

    const isAnyPriceVarious = getIsCheckoutPriceVarious(
        isExternalSubscription,
        invoiceDetails,
        checkoutItems
    );

    // TODO: Remove as IMO this goes against multiple item support
    const isOneTimePayment = !mainItem?.frequency.value;

    const {
        tokenTotalsReady,
        totalPricePerCommonToken,
        getTotalsByToken,
        getTotalPriceByAddressAndNetwork,
    } = useTotalPricePerCommonToken(checkoutTokens, checkoutItems);

    const haveTokensThatConvertToUsd =
        checkoutTokens.length - totalPricePerCommonToken.length > 0;

    // Accumulate the amount in USD, assuming there are still tokens without a total priced in ERC-20
    const usdTotalWithoutDiscount = haveTokensThatConvertToUsd
        ? getItemsUsdTotal({ items: checkoutItems, withDiscount: false })
        : null;

    // Total for items with amounts due today, assuming there are still tokens without a total priced in ERC-20
    const usdTotalDueToday = haveTokensThatConvertToUsd
        ? getUsdTotalDueToday(invoiceDetails, checkoutItems)
        : null;

    const totalDueLaterGroupedByDueDate = useMemo(
        () =>
            checkoutItems
                .filter(
                    ({ dueDate, amountAfterDiscount, prices }) =>
                        dueDate !== 0 &&
                        (amountAfterDiscount !== null ||
                            prices.some(
                                ({ amountAfterDiscount }) =>
                                    amountAfterDiscount !== null
                            ))
                )
                .reduce(
                    (
                        days: TotalDueLaterGroupedByDueDate,
                        {
                            dueDate,
                            amountAfterDiscount: usdAmountAfterDiscount,
                            prices,
                        }
                    ) => {
                        if (
                            usdAmountAfterDiscount === null &&
                            !prices.some(
                                ({ amountAfterDiscount }) =>
                                    amountAfterDiscount !== null
                            )
                        )
                            return days;

                        const dueLater: TotalDueLater = days[dueDate] || {
                            usd: null,
                            tokens: [],
                        };

                        if (usdAmountAfterDiscount !== null) {
                            dueLater.usd =
                                (dueLater?.usd ?? 0) + usdAmountAfterDiscount;
                        }

                        dueLater.tokens = prices.reduce<
                            TotalDueLater["tokens"]
                        >(
                            (
                                tokenPrices,
                                {
                                    tokenAddress,
                                    network,
                                    amountAfterDiscount:
                                        tokenAmountAfterDiscount,
                                }
                            ) => {
                                if (tokenAmountAfterDiscount !== null) {
                                    const existingDueDateTokenPrice =
                                        tokenPrices.find(
                                            ({
                                                tokenAddress:
                                                    existingTokenAddress,
                                                network: existingNetwork,
                                            }) =>
                                                existingTokenAddress ===
                                                    tokenAddress &&
                                                existingNetwork === network
                                        );

                                    if (existingDueDateTokenPrice) {
                                        existingDueDateTokenPrice.amount =
                                            String(
                                                BigInt(
                                                    existingDueDateTokenPrice.amount
                                                ) + tokenAmountAfterDiscount
                                            );
                                    } else {
                                        tokenPrices.push({
                                            tokenAddress,
                                            network,
                                            amount: String(
                                                tokenAmountAfterDiscount
                                            ),
                                        });
                                    }
                                }

                                return tokenPrices;
                            },
                            dueLater.tokens
                        );

                        days[dueDate] = dueLater;
                        return days;
                    },
                    {}
                ),
        [checkoutItems]
    );

    const minimumBalance = useMinimumBalance(
        invoiceDetails?.amount,
        items || [],
        queryParams.minimumBalanceRequired,
        coupons
    );

    // This will just be replaced with minimumBalance
    const minimumAllowance = useMinimumAllowance(
        invoiceDetails?.amount,
        items || [],
        queryParams.minimumBalanceRequired,
        coupons
    );

    const suggestedAllowance = useSuggestedAllowance(
        invoiceDetails?.amount,
        items || [],
        queryParams.minimumBalanceRequired,
        queryParams.defaultSpendingCap,
        coupons
    );

    const priceError: string =
        tokens &&
        tokenTotalsReady &&
        !totalPricePerCommonToken.length &&
        usdTotalWithoutDiscount === null &&
        usdTotalDueToday === null // If invoice has an amount, this will not be null
            ? `One or more items do not have a price that can be checked out`
            : ``;

    const fetchSuccess = useMemo(
        () => success && !error && !tokenError && !priceError,
        [success, error, tokenError, priceError]
    );

    const isSearchByInvoice = useMemo(
        () => fetchSuccess && !mainItem && !itemId,
        [fetchSuccess, mainItem, itemId]
    );

    // [ ] Replace this with useNetworkOnChain
    const { getAvailableNetworks } = useAvailableNetworks();
    const checkoutNetworks: CheckoutNetwork[] = useMemo(() => {
        if (!networks) return [];

        const availableNetworks = getAvailableNetworks();

        return networks.reduce<CheckoutNetwork[]>(
            (acc: CheckoutNetwork[], network: CheckoutNetworkResponse) => {
                const networkData = availableNetworks.find(
                    ({ id }) => id === network.id
                );
                if (!networkData) return acc;
                return [
                    ...acc,
                    {
                        ...network,
                        chain: networkData.chain,
                    },
                ];
            },
            []
        );
    }, [networks, getAvailableNetworks]);

    useEffect(() => {
        setMainItem(
            checkoutItems.find(({ id }) => id === itemId) ||
                checkoutItems?.at(0)
        );
    }, [itemId, checkoutItems, mainItem]);

    useEffect(() => {
        if (!entity || !mainItem) return;

        Sentry.setTag("entityId", entity.entityId);
        Sentry.setTag("itemId", mainItem.id);
        Sentry.setTag("externalId", queryParams.sub);
        Sentry.setTag("couponCode", queryParams.coupon);
        Sentry.setTag(
            "discountPercent",
            queryParams.discountPercent?.toString() || "0"
        );
    }, [
        queryParams.sub,
        entity,
        mainItem,
        queryParams.discountPercent,
        queryParams.coupon,
    ]);

    // This needs to be looked at - why is there an extra useEffect here?
    useEffect(() => {
        if (validExternalSubscription !== false) return;
    }, [validExternalSubscription, mainItem, entity, queryParams.sub]);

    useEffect(() => {
        if (!checkoutComplete) return;

        Sentry.setTag("Checkout complete", true);
    }, [checkoutComplete]);

    return (
        <CheckoutDataContext.Provider
            value={{
                fetchLoading: loading,
                fetchError: error || tokenError || priceError || "",
                fetchSuccess,
                isSearchByInvoice,
                checkoutComplete,
                setCheckoutComplete,
                queryParams,
                networks: checkoutNetworks,
                tokens: checkoutTokens || [],
                wrappableTokens,
                contracts: contracts || [],
                entity,
                mainItem,
                // TODO: Remove these
                isOneTimePayment,
                // TODO: Remove these
                isAnyPriceVarious,
                hasFreeTrial,
                coupons,
                setCoupons,
                items: checkoutItems,
                couponsWithDetails,
                totalPricePerCommonToken,
                getTotalsByToken,
                getTotalPriceByAddressAndNetwork,
                usdTotalWithoutDiscount,
                usdTotalDueToday,
                isInvoicedCheckout,
                isExternalSubscription,
                invalidSubscriptionId,
                invalidCustomerId,
                totalDueLaterGroupedByDueDate,
                formErrorDisplay,
                minimumAllowance,
                suggestedAllowance,
                invoiceDetails,
                minimumBalance,
                dueDateForDisplay,
                externalEmail: email || null,
            }}
        >
            {children}
        </CheckoutDataContext.Provider>
    );
};

const useCheckoutData = (): CheckoutDataValue => {
    const context = useContext(CheckoutDataContext);
    if (context === undefined) {
        throw new Error(
            `useCheckoutData() must be used within a CheckoutDataProvider`
        );
    }
    return context;
};

export { CheckoutDataProvider, useCheckoutData };
